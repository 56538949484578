<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa-solid fa-calendar" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="eventAdd" v-if="userPermission('event_add')">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row>
              <b-col sm="6" md="6" lg="3" xl="3" class="mb-2">
                <!-- <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input> -->

                <!-- Backend Search -->
                <input class="form-control"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchUserWithParams"
                  :placeholder="cvSearchText"
                /><!-- Backend Search -->
              </b-col>
              <b-col sm="6" md="3" lg="3" xl="3" class="mb-2">
                <select v-model="whereFilter.event_status" class="form-control" @change="setEventStatusFilter()">
                  <option value="All">All</option>
                  <option value="Upcoming Event">Upcoming Event</option>
                  <option value="Past Event">Past Event</option>
                </select>
              </b-col>
              <b-col sm="6" md="3" lg="3" xl="3" class="mb-2">
                  <select v-model="whereFilter.event_state" class="form-control" @change="setEventStateFilter()">
                  <option :value="99">All</option>
                  <option :value="0">Publish</option>
                  <option :value="2">Unpublish</option>
                  <option :value="1">Delete</option>
                </select>
              </b-col>
              <b-col sm="6" md="12" lg="3" xl="3" class="mb-2">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getEventListDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="eventObjList && eventObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="eventObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(deleted)="data">
                    <b-badge v-if="data.item.deleted === 0" class="pointer ml-1" variant="success mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Published&nbsp;
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Unpublished
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Deleted
                    </b-badge>

                    <b-button variant="light mr-1 mb-1" size="sm" :disabled="data.item.deleted === 2" @click="eventPublishUnpublish(data.item)">
                      <i class="fa fa-toggle-off m-0 font-size-20" title="Unpublish" style="color:red"></i>
                    </b-button>
                    <b-button variant="light mr-1 mb-1" :disabled="data.item.deleted === 0" size="sm" @click="eventPublishUnpublish(data.item)">
                      <i class="fa fa-toggle-on m-0 font-size-20" title="Publish" style="color:green"></i>
                    </b-button>
                  </template>

                  <template v-slot:cell(event_name)="data">
                    {{getLimitedText(data.item.event_name)}} <br/>
                    <i style="font-size:10px" title="Gide Meeting Id">Event Id: {{data.item.event_id}}</i> <br/>
                    <i v-if="data.item.event_meeting_id" style="font-size:10px" title="Zoom/MSTeams/gMeeting">Meeting Id: {{data.item.event_meeting_id}}<br/></i>
                    <b-badge v-if="data.item.event_status === 'Past Event'" variant="secondary"> {{data.item.event_status}} </b-badge>
                    <b-badge v-if="data.item.event_status === 'Upcoming Event'" variant="info" style=""> {{data.item.event_status}} </b-badge>
                    <b-badge v-if="data.item.deleted === 0" class="pointer ml-1" variant="success mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Published&nbsp;
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Unpublished
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Deleted
                    </b-badge>
                    <b-badge class="pointer ml-1" variant="primary mr-1 mb-1" size="sm" @click="openPublicLinkV1(data.item)">
                      <i class="fa-brands fa-chrome" title="View Event Marketing Page"></i>
                    </b-badge>
                  </template>

                  <template v-slot:cell(event_status)="data">
                    {{data.item.event_status}}
                    <br>
                    <b-badge v-if="data.item.event_hosting_platform == eventHostingPlatformConfig.ZOOM && current_utc_ts <= data.item.event_start_ts" class="pointer" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Start Meeting
                    </b-badge>
                  </template>

                  <template v-slot:cell(event_total_join_percentage)="data">
                    {{data.item.event_total_regs !== 0 ? (data.item.event_total_joins / data.item.event_total_regs * 100).toFixed(2) : 0 }}%
                  </template>

                  <template v-slot:cell(event_attendance_percentage)="data">
                    {{data.item.event_total_joins !== 0 ? (data.item.event_total_attendees / data.item.event_total_joins * 100).toFixed(2) : 0 }}%
                  </template>

                  <template v-slot:cell(event_start_ts)="data">
                    {{data.item.event_start_ts | dateFormatDDMMYYYYHHMMA}}
                  </template>

                  <template v-slot:cell(event_duration)="data">
                    {{data.item.event_duration | durationToDHM}}
                  </template>

                  <template v-slot:cell(action)="data">
                    <button class="actionIcon ml-2 mr-3" v-if="checkShowEventEdit(data)" title="Edit Event" @click="eventEdit('MODAL', data.item)"> <i class="fa fa-pencil-square-o"></i></button>
                    <button class="actionIcon ml-2 mr-3" disabled v-if="!(checkShowEventEdit(data))"><i class="fa fa-pencil-square-o"></i></button>
                    <button class="ml-0 mr-2 font-size-22 actionIcon" v-if="userPermission('event_delete')" title="Delete Event"  @click="showeventDeleteDialog(data.item)">  <i class="ri-delete-bin-5-fill pointer"></i></button>
                    <button class="ml-2 mr-3 actionIcon" v-if="userPermission('eventRsvp_list')" title="View Registered Users" @click="goToPBARsvpUsersList(data.item)">  <i class="fa fa-users  pointer"></i></button>
                    <button class="ml-2 mr-3 actionIcon" disabled v-if="!userPermission('eventRsvp_list')"><i class="fa fa-users  pointer"></i></button>
                    <button class="ml-0 mr-2 actionIcon" v-if="userPermission('view_zoom_participants') && data.item.event_hosting_platform != eventHostingPlatformConfig.ZOOM" title="Check in Report" @click="goToCheckinReport(data.item)">  <i class="fa-solid fa-check-square pointer"></i></button>
                    <button class="ml-0 mr-2 actionIcon" disabled v-if="!(userPermission('view_zoom_participants') && data.item.event_hosting_platform != eventHostingPlatformConfig.ZOOM)"><i class="fa-solid fa-check-square pointer"></i></button>
                    <button class="ml-2 actionIcon" @click="eventview(data.item.event_id)"><i class="fa-solid fa-eye pointer" title="View Event Details"></i></button>
                    <button class="ml-2 actionIcon" size="sm" v-if="userPermission('event_edit')"  @click="duplicateEvent(data.item.event_id)"><i class="fa fa-files-o" title="Duplicate This Event"></i></button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else-if="!loadingFlag">
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal v-model="showModeleventDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModeleventDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="eventDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
.dateRange {
  float: right;
}
.actionIcon {
  font-size: 20px;
  vertical-align: -webkit-baseline-middle;
  margin-bottom: 10px;
  border: 0px;
  background: transparent;
}
</style>
<script>
import { events } from "../FackApi/api/events.js"
import moment from "moment"
import ApiResponse from "../Utils/apiResponse.js"
import { socialvue } from "../config/pluginInit.js"
import eventHostingPlatformConfig from "../FackApi/json/EventHostingPlatforms.js"
import userPermission from "../Utils/user_permission.js"

export default {
  name: "eventList",
  components: {
  },
  data () {
    return {
      apiName: "event_list_dashboard",
      cvCardTitle: "Event Dashboard",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit event",
      cvAddModalHeader: "Add event",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event List Response",
      showModeleventAdd: false,
      showModeleventEdit: false,
      showModeleventDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [],
      eventObjList: null,
      eventEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      propShowPBARsvpModal: false,
      propPBAId: null,
      cvModulePrefix: "EVE",
      current_utc_ts: parseInt(moment.utc().valueOf() / 1000),
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 1)), // From Last Month
          endDate: new Date(new Date().setDate(new Date().getDate() + 30))
        },
        search_param: "",
        event_status: "Upcoming Event",
        event_state: 99 // All
      },
      loadingFlag: false,
      eventHostingPlatformConfig: eventHostingPlatformConfig,
      eventPublicViewLinkV1: `${process.env.VUE_APP_SERVER_URL}/contactUsV1?source=WEB&module_id={EVENT_ID}&module_name={NAME}`
    }
  },
  computed: {
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    userData () {
      return this.$store.getters["User/userData"]
    },
    eventState () {
      return this.$store.getters["EventState/selectedEventState"]
    },
    eventStatus () {
      return this.$store.getters["EventStatus/selectedEventStatus"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.eventHostingPlatformConfig = eventHostingPlatformConfig(this.userData.user_role)
    await this.getFiltersFromCache()
    this.eventListDashboard()

    let self = this
    switch (this.userData.user_role) {
      case "USERROLE11115": // Univ
      case "USERROLE11116": // Org
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
          { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false },
          { label: "State", key: "event_status", class: "text-left align-text-top", sortable: true },
          { label: "Name", key: "event_name", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Status ", key: "deleted", class: "text-left align-text-top", sortable: true },
          { label: "Starts On", key: "event_start_ts", class: "text-left align-text-top", sortable: true },
          { label: "Registrations ", key: "event_total_regs", class: "text-left align-text-top", sortable: true },
          { label: "Joinees ", key: "event_total_joins", class: "text-left align-text-top", sortable: true },
          { label: "Join % ", key: "event_total_join_percentage", class: "text-left align-text-top", sortable: true },
          { label: "Attendees ", key: "event_total_attendees", class: "text-left align-text-top", sortable: true },
          { label: "Attnd. % ", key: "event_attendance_percentage", class: "text-left align-text-top text-nowrap w-100px", sortable: true }
        ]
        break
      case "USERROLE11111": // What GIDE Admin Sees
      case "USERROLE11118": // Counsellor
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
          { label: "Action ", key: "action", class: "text-left align-text-top w-125px", sortable: false },
          { label: "Name", key: "event_name", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Status ", key: "deleted", class: "text-left align-text-top", sortable: true },
          { label: "State", key: "event_status", class: "text-left align-text-top", sortable: true },
          { label: "Starts On", key: "event_start_ts", class: "text-left align-text-top", sortable: true },
          { label: "Duration", key: "event_duration", class: "text-left align-text-top", sortable: true },
          { label: "Type", key: "event_type_name", class: "text-left align-text-top", sortable: true },
          { label: "Level", key: "event_lev_name", class: "text-left align-text-top", sortable: true },
          { label: "Registrations ", key: "event_total_regs", class: "text-left align-text-top", sortable: true },
          { label: "Joinees ", key: "event_total_joins", class: "text-left align-text-top", sortable: true },
          { label: "Join % ", key: "event_total_join_percentage", class: "text-left align-text-top", sortable: true },
          { label: "Attendees ", key: "event_total_attendees", class: "text-left align-text-top", sortable: true },
          { label: "Attnd. % ", key: "event_attendance_percentage", class: "text-left align-text-top text-nowrap w-100px", sortable: true }
        ]
        break
      default:
        break
    }
  },
  methods: {
    /**
      * get data from store cache by this method
      */
    async getFiltersFromCache () {
      await this.getDateFilter()
      await this.getEventStateFilter()
      await this.getEventStatusFilter()
    },
    /**
      * Setting Date Range in the LS
      */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * userPermission
     */
    userPermission (actionName = null) {
      return userPermission(this, this.userData, actionName)
    },
    /**
      * get Date Filters From Store
      */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Set Event State Filter
     * Upcoming, Past
     */
    setEventStateFilter () {
      this.$store.commit("EventState/addEventState", this.whereFilter.event_state)
      this.eventListDashboard()
    },
    /**
     * Get Event State Filter
     * Upcoming, Past
     */
    async getEventStateFilter () {
      let EventState = await this.eventState
      if (EventState) {
        this.whereFilter.event_state = EventState
      }
    },
    /**
     * duplicateEvent
     */
    async duplicateEvent (eventId) {
      try {
        const duplicateEventResp = await events.eventDuplicate(this, { event_id: eventId })
        if (!duplicateEventResp.resp_status) {
          return
          // handle toast message
        }
        window.open(`/event/${duplicateEventResp.resp_data.event_id}`, "_blank")
        // reditrect to the duplicated event page
      }
      catch (err) {
        console.error("Exception in duplicateEvent and err: ", err)
      }
    },
    /**
     * Set Event Status Filter
     * Publishe, UnPublished, Deleted
     */
    setEventStatusFilter () {
      this.$store.commit("EventStatus/addEventStatus", this.whereFilter.event_status)
      this.eventListDashboard()
    },
    /**
     * get Event Status Filter
     * Publishe, UnPublished, Deleted
     */
    async getEventStatusFilter () {
      let EventStatus = await this.eventStatus
      if (EventStatus) {
        this.whereFilter.event_status = EventStatus
      }
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.currentBatchNo = 1
      await this.eventListDashboard()
    },
    /**
     * checkShowEventEdit
    */
    checkShowEventEdit (data) {
      /*
      if (this.userPermission("event_edit") &&
        ((data.item.event_start_ts > this.current_utc_ts && this.current_utc_ts < data.item.event_end_ts) || !data.item.event_start_ts ||
          (data.item.event_hosting_platform === eventHostingPlatformConfig.ZOOM && !data.item.event_meeting_id)
        )) {
        return true
      }
      else {
        return false
      }
      */
      return true
    },
    /**
     * openUrlInNew
    */
    openUrlInNew (url) {
      window.open(url, "_blank")
    },
    /**
     * getLimitedText
     */
    getLimitedText (text) {
      if (text) {
        return text.substr(0, 100)
      }
      else {
        return text
      }
    },
    /**
     * eventPublishUnpublish
     */
    async eventPublishUnpublish (event) {
      let payload = {
        event_id: event.event_id,
        deleted: null,
        title: event.event_name,
        desc: event.event_desc
      }

      if (event.deleted === 0) {
        // event is published then unpublish
        payload.deleted = 2
      }
      else if (event.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      const eventResp = await events.eventPublishUnpublish(this, payload)
      if (eventResp && eventResp.resp_status) {
        event.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, eventResp)
      }
    },
    /**
     * Calling Function as per whereFilter
     */
    async getEventListDateWise () {
      this.setDateFilter()
      this.eventListDashboard()
    },
    /**
     * eventListDashboard
     */
    async eventListDashboard (loadViaLoadMore = false) {
      this.loadingFlag = true
      try {
        let eventListResp = await events.eventListDashboard(this, this.whereFilter)
        if (eventListResp.resp_status) {
          if (this.whereFilter.search_param && this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.eventObjList = [ ...eventListResp.resp_data.data ]
          }
          else {
            this.eventObjList = eventListResp.resp_data.data
          }
          this.totalRows = eventListResp.resp_data.count
        }
        else {
          this.toastMsg = eventListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventList() and Exception:", err.message)
      }
      finally {
        this.loadingFlag = false
      }
    },
    /**
     * eventAdd
     */
    eventAdd (type) {
      try {
        window.open("/event_add")
      }
      catch (err) {
        console.error("Exception occurred at eventAdd() and Exception:", err.message)
      }
    },
    /**
     * eventEdit
     */
    eventEdit (type, item) {
      try {
        this.eventEditObj = item
        const path = "/event_edit/" + this.eventEditObj.event_id
        window.open(path)
      }
      catch (err) {
        console.error("Exception occurred at eventEdit() and Exception:", err.message)
      }
    },
    /**
     * eventView
     */
    eventview (eventId) {
      try {
        const path = "/event/" + eventId
        // window.open(this.$router.push(path))
        window.open(path, "blank")
      }
      catch (err) {
        console.error("Exception occurred at eventview() and Exception:", err.message)
      }
    },
    /**
     * showeventDeleteDialog
     */
    showeventDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModeleventDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showeventDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * eventDelete
     */
    async eventDelete () {
      try {
        let eventDelResp = await events.eventDelete(this, this.delObj.event_id)
        await ApiResponse.responseMessageDisplay(this, eventDelResp)
        if (eventDelResp && !eventDelResp) {
          this.showModeleventDelete = false
          return false
        }
        let index = this.eventObjList.indexOf(this.delObj)
        this.eventObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModeleventDelete = false
      }
      catch (err) {
        console.error("Exception occurred at eventDelete() and Exception:", err.message)
      }
    },
    /**
     * closeeventAddModal
     */
    closeeventAddModal (eventAddData) {
      try {
        if (eventAddData) {
          if (this.eventObjList && this.eventObjList.length >= 1) {
            let profBulActObjLength = this.eventObjList.length
            let lastId = this.eventObjList[profBulActObjLength - 1]["id"]
            eventAddData.id = lastId + 1
          }
          else {
            this.eventObjList = []
            eventAddData.id = 11111
          }
          // eventAddData.event_id = `EVE${eventAddData.id}`
          eventAddData.created_on = moment()
          this.eventObjList.unshift(eventAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModeleventAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeeventAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeeventEditModal
     */
    closeeventEditModal () {
      try {
        this.showModeleventEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeeventEditModal() and Exception:", err.message)
      }
    },
    /**
     * goToPBARsvpUsersList
     */
    goToPBARsvpUsersList (event) {
      window.open(`/event_participant_dashboard/${event.event_id}?meeting_id=${event.event_meeting_id}`)
    },
    /**
     * goToCheckinReport
     */
    goToCheckinReport (event) {
      window.open(`/event_analytics_booth_checkin_report/${event.event_id}`)
    },
    /**
     * open Public LinkV1
     */
    openPublicLinkV1 (event) {
      this.eventPublicViewLinkV1 = `${process.env.VUE_APP_SERVER_URL}/contactUsV1?source=WEB&module_id=${event.event_id}&module_name=${event.event_name}`
      window.open(this.eventPublicViewLinkV1, "_blank")
    }
  }
}
</script>
